import Layout from '../../components/layout'
import React, { FunctionComponent } from 'react'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box/Box'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { AcademicSignupForm } from './_academicSignup'

const citeString = `@inproceedings{perry-2021-lighttag,
            title = "{L}ight{T}ag: Text Annotation Platform",
            author = "Perry, Tal",
            booktitle = "Proceedings of the 2021 Conference on Empirical Methods in Natural Language Processing: System Demonstrations",
            month = nov,
            year = "2021",
            address = "Online and Punta Cana, Dominican Republic",
            publisher = "Association for Computational Linguistics",
            url = "https://aclanthology.org/2021.emnlp-demo.3",
            pages = "20--27",
}`
const useStyles = makeStyles(theme=>(
  {
    citeBox:{
      boxShadow:'0 4px 20px rgb(0 0 0 / 15%)',
      padding:theme.spacing(2),
      marginBottom:theme.spacing(2),
      borderRadius:'8px',
      whiteSpace:'pre-wrap',
      fontSize: '4px',
      lineHeight: '1rem'
    },
    termsOfUse:{
      lineHeight:'1.4rem',
      textAlign:'justify',
      fontSize:'17px',
      padding:theme.spacing(2,0),

      whiteSpace:'pre-wrap'

    }
  }
))
const LeftPane :FunctionComponent = ()=>{


  const classes=useStyles()
  const theme= useTheme()
  return (
    <Grid item xs={6} container>
      <Grid item xs={12}>
        <Typography variant={"h4"} style={{paddingBottom:theme.spacing(1),fontSize:'32px'}}>
          For Schools and Universities
        </Typography>

        <Typography variant={"h5"}>
          Citing LightTag
        </Typography>
        <Typography variant={"body1"}>
          Please cite LightTag in your research papers as follows:
        </Typography>
        <Box className={classes.citeBox}>
          <Typography variant={"caption"} style={{
            fontSize:'10px',
            fontFamily:'monospace',
            lineHeight:'1rem'
          }}>
            {citeString}
          </Typography>
        </Box>

      </Grid>
      <Grid item xs={12} />
      <Grid item xs={12}>
        <Typography variant={"h5"} style={{paddingBottom:theme.spacing(1)}}>
          Education Terms of Use
        </Typography>
        <Typography className={classes.termsOfUse}>
          <p>Access to LightTag Services, including without limitation, free access for educational purposes (“Education License”), is subject to acceptance of and compliance with the Terms or other applicable terms that accompany such Services. Under an Education License the Services are additionally subject to these Education Terms.</p>


          <p>Individuals who are at least eighteen (18) years old and are enrolled full time in an educational institution that has been accredited by an authorized governmental agency within its applicable local, state, federal or national government and has the primary purpose of teaching its enrolled students (“Qualified Educational Institution”) (“Students”), as well as individuals who are employees or contractors working for a Qualified Educational Institution (“Faculty”) may apply for an Education License to use the Services at: http://www.LightTag.com/education</p>

          <p>No other persons are granted Education Licenses. If an individual no longer qualifies as a Student or as Faculty, then the Education License shall terminate immediately. For clarity, the following are specifically excluded, without limitation, from the definition of Qualified Educational Institution: libraries, museums, training centers, churches, hospitals, healthcare systems & research laboratories and non-accredited educational institutions. Furthermore, institutions in countries subject to United States trade embargo are specifically excluded from the definition of Qualified Educational Institution.</p>

          <p>LightTag reserves the right to reject Applications, or to suspend offering Education Licenses at any time, for any reason at its sole discretion.</p>

          <p>An Education License may be used solely for purposes directly related to learning, training or research (“Educational Purposes”) and shall not be used for commercial, professional or any other for-profit purposes.</p>

          <p>LightTag may require any Students or Faculty using the Services under an Education License to show proof of eligibility to LightTag.</p>


        </Typography>
      </Grid>
    </Grid>
  )
}
const RightPange :FunctionComponent = ()=>{
  const theme = useTheme()
  return (<Grid item xs={6} container alignItems={"flex-start"} direction={"column"}>
      <Grid item >
        <Typography variant={"h4"} style={{paddingBottom:theme.spacing(1),fontSize:'32px'}}>
          We are not accepting new users at this time
        </Typography>
      </Grid>
      <Grid item >
        {/*<AcademicSignupForm  />*/}
      </Grid>

  </Grid>
  )
}
const Academic :FunctionComponent = ()=> {
  return (<Layout
      backgroundColor="#FAFAFA"
      pageTitle={`LightTag Academic Program `}
      pageDescription={"Signup for a Free LightTag Academic license today. LightTag's text annotation platform is trusted by leading companies and universities worldwide"}
    >
    <Container>
      <Grid

        container
        alignItems={'stretch'}
        spacing={6}
        justify={'space-between'}
      >
        <LeftPane />
        <RightPange />
      </Grid>
    </Container>
    </Layout>
  )
}
export default Academic

